import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@emotion/react";
import { css } from "@emotion/react";
import theme from "./theme";
import "./layout.css";

const containerStyle = (theme) => css`
  ${theme.typography.basic};
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AccessibilityColorsContext = React.createContext(null);

const Layout = ({ children, language = "pl" }) => {
  const [accesibleMode, setAccessibleMode] = React.useState(false);
  const properTheme = accesibleMode
    ? {
        ...theme,
        colors: theme.accessibleColors,
      }
    : theme;
  return (
    <AccessibilityColorsContext.Provider
      value={{ accesibleMode, setAccessibleMode }}
    >
      <ThemeProvider theme={properTheme}>
        <div css={containerStyle}>
          <div
            style={{
              margin: `0 auto`,
              padding: `0`,
              width: "100%",
            }}
          >
            <main>{children}</main>
          </div>
        </div>
      </ThemeProvider>
    </AccessibilityColorsContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

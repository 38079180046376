// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formularz-js": () => import("./../../../src/pages/formularz.js" /* webpackChunkName: "component---src-pages-formularz-js" */),
  "component---src-pages-glass-go-js": () => import("./../../../src/pages/glass-go.js" /* webpackChunkName: "component---src-pages-glass-go-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-riddles-4-cj-rsp-uo-rj-js": () => import("./../../../src/pages/riddles/4cjRSPUoRj.js" /* webpackChunkName: "component---src-pages-riddles-4-cj-rsp-uo-rj-js" */),
  "component---src-pages-riddles-g-p-60-vt-1-j-7-z-js": () => import("./../../../src/pages/riddles/gP60VT1J7Z.js" /* webpackChunkName: "component---src-pages-riddles-g-p-60-vt-1-j-7-z-js" */),
  "component---src-pages-riddles-lwp-ug-pgfu-5-js": () => import("./../../../src/pages/riddles/lwpUGPgfu5.js" /* webpackChunkName: "component---src-pages-riddles-lwp-ug-pgfu-5-js" */),
  "component---src-pages-riddles-ml-8-nkbf-fdd-js": () => import("./../../../src/pages/riddles/ml8NkbfFdd.js" /* webpackChunkName: "component---src-pages-riddles-ml-8-nkbf-fdd-js" */),
  "component---src-pages-riddles-uo-go-ol-qfo-5-js": () => import("./../../../src/pages/riddles/uoGoOLQfo5.js" /* webpackChunkName: "component---src-pages-riddles-uo-go-ol-qfo-5-js" */),
  "component---src-pages-riddles-zn-ke-8-a-l-ii-5-js": () => import("./../../../src/pages/riddles/znKe8aLIi5.js" /* webpackChunkName: "component---src-pages-riddles-zn-ke-8-a-l-ii-5-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-zagadki-4-cj-rsp-uo-rj-js": () => import("./../../../src/pages/zagadki/4cjRSPUoRj.js" /* webpackChunkName: "component---src-pages-zagadki-4-cj-rsp-uo-rj-js" */),
  "component---src-pages-zagadki-g-p-60-vt-1-j-7-z-js": () => import("./../../../src/pages/zagadki/gP60VT1J7Z.js" /* webpackChunkName: "component---src-pages-zagadki-g-p-60-vt-1-j-7-z-js" */),
  "component---src-pages-zagadki-lwp-ug-pgfu-5-js": () => import("./../../../src/pages/zagadki/lwpUGPgfu5.js" /* webpackChunkName: "component---src-pages-zagadki-lwp-ug-pgfu-5-js" */),
  "component---src-pages-zagadki-ml-8-nkbf-fdd-js": () => import("./../../../src/pages/zagadki/ml8NkbfFdd.js" /* webpackChunkName: "component---src-pages-zagadki-ml-8-nkbf-fdd-js" */),
  "component---src-pages-zagadki-uo-go-ol-qfo-5-js": () => import("./../../../src/pages/zagadki/uoGoOLQfo5.js" /* webpackChunkName: "component---src-pages-zagadki-uo-go-ol-qfo-5-js" */),
  "component---src-pages-zagadki-zn-ke-8-a-l-ii-5-js": () => import("./../../../src/pages/zagadki/znKe8aLIi5.js" /* webpackChunkName: "component---src-pages-zagadki-zn-ke-8-a-l-ii-5-js" */),
  "component---src-pages-zasady-gry-js": () => import("./../../../src/pages/zasady-gry.js" /* webpackChunkName: "component---src-pages-zasady-gry-js" */)
}


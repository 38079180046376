import { css } from "@emotion/react";

const colors = {
  black: "#000",
  blackText: "#000",
  darkGrey: "#1d1d1d",
  red: "#dc491d",
  white: "#fff",
  whiteText: "#fff",
};

const breakpoints = {
  smallDesktop: "@media (max-width: 1100px)",
  tablet: "@media (max-width: 770px)",
  mobile: "@media (max-width: 550px)",
};

const grids = {
  split: css`
    display: grid;
    grid-template-columns: 1fr 1fr;

    ${breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  `,
  triple: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    ${breakpoints.tablet} {
      grid-template-columns: 1fr 1fr;
    }

    ${breakpoints.mobile} {
      grid-template-columns: 1fr;
    }
  `,
  fortyToOne: css`
    display: grid;
    grid-template-columns: 40% 1fr;

    ${breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  `,
  thirtyToOne: css`
    display: grid;
    grid-template-columns: 30% 1fr;

    ${breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  `,
  steps: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    ${breakpoints.smallDesktop} {
      grid-template-columns: 1fr 1fr 1fr;
    }

    ${breakpoints.tablet} {
      grid-template-columns: 1fr 1fr;
    }

    ${breakpoints.mobile} {
      grid-template-columns: 1fr;
    }
  `,
};

const accessibleColors = {
  black: "#000",
  blackText: "#000",
  darkGrey: "#fff",
  red: "#fff",
  white: "#fff",
  whiteText: "#000",
};

const typography = {
  bold: {
    fontWeight: 500,
  },
  basic: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    color: colors.black,
    fontSize: "1rem",
    fontWeight: 500,
  },
  navigation: {
    fontSize: "1.2rem",
  },
  big: {
    fontSize: "7.5rem",
  },
  lead: {
    fontSize: "12.5rem",
    fontWeight: 500,
  },
  smallLead: {
    fontSize: "3rem",
    fontWeight: 500,
    lineHeight: "1.1em",
  },
  articleTitle: {
    fontSize: "5.5rem",
    fontWeight: 500,
    lineHeight: "1.1em",
  },
};

const emotionTypography = {
  lead: css`
    ${typography.lead};

    ${breakpoints.smallDesktop} {
      font-size: 10rem;
    }
    ${breakpoints.tablet} {
      font-size: 9rem;
    }

    ${breakpoints.mobile} {
      font-size: 4rem;
    }
  `,
  smallLead: css`
    ${typography.smallLead};

    ${breakpoints.smallDesktop} {
      font-size: 2.5rem;
    }
    ${breakpoints.tablet} {
      font-size: 2rem;
    }

    ${breakpoints.mobile} {
      font-size: 1.5rem;
    }
  `,
  big: css`
    ${typography.big};

    ${breakpoints.smallDesktop} {
      font-size: 6.5rem;
    }

    ${breakpoints.tablet} {
      font-size: 5rem;
    }
    ${breakpoints.mobile} {
      font-size: 3rem;
    }
  `,
};

const theme = {
  colors,
  accessibleColors,
  typography,
  breakpoints,
  emotionTypography,
  grids,
};

export default theme;
